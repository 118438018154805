@import "_globals";

.newstipp-infobox {
    .newstipp-infobox-image {
        width: 100%;
        height: 128px;
        margin-bottom: 34px;

        img {
            width: 100%;
            object-fit: cover;
        }
    }
    .news-seminar--further-dates {
        .further-dates--label {
            font-weight: 500;
        }

        ul {
            padding: 0 !important;

            li {
                list-style: none;

                .further-dates--link  {
                    font-weight: normal;
                    color: @c-darkgray-500;
                }
            }
        }
    }
}


.newstipp-item {
    margin-bottom: 26px;

    .newstipp-header {
        margin-bottom: 15px;

        .newstipp-title {

            .main-focus {
                margin-bottom: 5px;
                //font-weight: 500;
                font-size: 14px;
                line-height: 19px;
                text-transform: uppercase;
            }

            .title {
                font-size: 18px;
                line-height: 23px;
            }
        }
    }
}

.newstipp-content-item, .seminar-teaser-content-details-item {
    display: flex;
    flex-flow: row nowrap;
    gap: 10px;

    dd, dt {
        font-size: 15px;
        color: @c-darkgray-400;
    }


    dt {
        font-weight: 500;
        flex-basis: 130px;
        flex-shrink: 0;
    }

    dd {
        flex-grow: 10;
        flex-basis: 100%;
    }
}