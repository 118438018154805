.newstipp-infobox .newstipp-infobox-image {
  width: 100%;
  height: 128px;
  margin-bottom: 34px;
}
.newstipp-infobox .newstipp-infobox-image img {
  width: 100%;
  object-fit: cover;
}
.newstipp-infobox .news-seminar--further-dates .further-dates--label {
  font-weight: 500;
}
.newstipp-infobox .news-seminar--further-dates ul {
  padding: 0 !important;
}
.newstipp-infobox .news-seminar--further-dates ul li {
  list-style: none;
}
.newstipp-infobox .news-seminar--further-dates ul li .further-dates--link {
  font-weight: normal;
  color: #454661;
}
.newstipp-item {
  margin-bottom: 26px;
}
.newstipp-item .newstipp-header {
  margin-bottom: 15px;
}
.newstipp-item .newstipp-header .newstipp-title .main-focus {
  margin-bottom: 5px;
  font-size: 14px;
  line-height: 19px;
  text-transform: uppercase;
}
.newstipp-item .newstipp-header .newstipp-title .title {
  font-size: 18px;
  line-height: 23px;
}
.newstipp-content-item,
.seminar-teaser-content-details-item {
  display: flex;
  flex-flow: row nowrap;
  gap: 10px;
}
.newstipp-content-item dd,
.seminar-teaser-content-details-item dd,
.newstipp-content-item dt,
.seminar-teaser-content-details-item dt {
  font-size: 15px;
  color: #424242;
}
.newstipp-content-item dt,
.seminar-teaser-content-details-item dt {
  font-weight: 500;
  flex-basis: 130px;
  flex-shrink: 0;
}
.newstipp-content-item dd,
.seminar-teaser-content-details-item dd {
  flex-grow: 10;
  flex-basis: 100%;
}
